<template>
    <main-layout>
        <template #content>
            <header-content :header-info="{ ...headerInfo, counter: getterBookmarksLength }" />
            <div class="bookmarks">
                <div class="bookmarks-wrapper wrapper">
                    <div class="bookmarks-list">
                        <div
                            v-for="(card, index) in getterBookmarks"
                            :key="card.referenceid"
                        >
                            <div
                                :key="index"
                                class="bookmarks-card card"
                            >
                                <div class="bookmarks-card__info">
                                    <span class="bookmarks-card__index">{{ index + 1 }}</span>
                                    <h3 class="bookmarks-card__title">
                                        «{{ card.details }}»
                                    </h3>
                                    <router-link
                                        :to="followToId(card.referenceid)"
                                        class="bookmarks-card__id"
                                    >
                                        {{ card.referenceid }}
                                    </router-link>
                                    <span class="bookmarks-card__duedate">Created: {{ card.created_at | moment_from }}</span>
                                </div>
                                <div class="bookmarks-card__action">
                                    <div
                                        class="bookmarks-card__delete"
                                        @click="openModal(card.id, card.referenceid)"
                                    >
                                        <span class="tool" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="loadmore-btn">
                        <custom-button
                            v-if="lastPage > page"
                            default
                            class="button btn-base_colored sort-button"
                            @on-btn-click="loadMore"
                        >
                            Load more
                        </custom-button>
                    </div>
                </div>
                <transition name="fade">
                    <div
                        v-if="!getterBookmarksLength"
                        class="bookmarks-not-found"
                    >
                        <not-found
                            text="You do not have featured entries."
                            desc="You can select orders and add them to your favorites for a quick transition."
                        />
                    </div>
                </transition>
                <delete-bookmark
                    v-if="showModal"
                    :id="bookmark"
                    :referenceid="referenceid"
                    @close="closeModal"
                    @removeBookmark="removeBookmark"
                />
            </div>
        </template>
    </main-layout>
</template>
<script>
import MainLayout from '@/layouts/MainLayout.vue'
import HeaderContent from '@/components/HeaderContent';
import DeleteBookmark from '@/components/modals/DeleteBookmark';
import NotFound from '@/components/NotFound'

// import Api from '@/helpers/api/index.js';
import filtersMixin from '@/mixins/filtersMixin.js';
// import { eventBus } from '@/helpers/event-bus'

import { createNamespacedHelpers, mapGetters } from 'vuex';
import { GET_DATA, GET_LOAD_MORE } from '../../store/modules/bookmarks/action-types';

const {
    mapActions: mapWriterActions
} = createNamespacedHelpers('bookmarks')

export default {
    components: {
        MainLayout,
        HeaderContent,
        DeleteBookmark,
        NotFound
    },
    metaInfo: {
        title: 'Bookmarks'
    },
    mixins: [filtersMixin],
    data() {
        return {
            showModal: false,
            bookmark: null,
            lastPage: null,
            page: 1,
            headerInfo: {
                title: 'Bookmarks',
                className: 'header-content__bookmarks',
                steps: [
                    {
                        title: 'Home',
                        url: '/'
                    },
                    {
                        title: 'Dashboard',
                        url: '/cp/account'
                    }
                ]
            }
        }
    },
    computed: {
        ...mapGetters('bookmarks', [
            'getterBookmarks',
            'getterBookmarksLength'
        ])
    },
    async created() {
        await this.getData()
    },
    methods: {
        ...mapWriterActions([
            GET_DATA,
            GET_LOAD_MORE
        ]),
        async getData() {
            const response = await this.GET_DATA()
            this.lastPage = response.last_page
            this.headerInfo = { ...this.headerInfo }
        },
        loadMore() {
            this.page += 1
            this.GET_LOAD_MORE({ page: this.page })
        },
        followToId(id) {
            const type = id?.split('-')[0]
            const link = type === 'S' ? { name: 'details', params: { id } } : { name: 'tickets', query: { ticketid: id } }
            return link
        },
        openModal(id, referenceid) {
            this.referenceid = referenceid
            this.bookmark = id
            this.showModal = true
        },
        closeModal() {
            this.showModal = false
        },
        removeBookmark() {
            this.closeModal()
        }
    }
}
</script>
<style lang="scss">
    .bookmarks {
        &-wrapper {
            margin-top: 40px;
        }
        &-card {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px;
            &__info {
                width: 90%;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }
            &__index {
                margin-right: 20px;
                color: $label-light-grey;
            }
            &__title {
                font-weight: 500;
                overflow: hidden;
                width: 30%;
                padding: 10px 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                @media (max-width:576px) {
                    width: 80%;
                }

            }
            &__id, &__duedate {
                width: 25%;
                padding: 10px 25px;
                display: flex;
                color: $font-color-main;
                white-space: nowrap;
                &::before {
                    content: '';
                    display: block;
                    width: 20px;
                    min-width: 20px;
                    height: 15px;
                    background: url('~@/assets/img/svg/order-id.svg') no-repeat;
                    background-size: contain;
                    margin-right: 10px;
                }
                @media (max-width:992px) {
                    width: 50%;
                }
            }
            &__duedate {
                &::before {
                    display: none;
                }
                @media (max-width:576px) {
                    width: 100%;
                }
            }
            &__id {
                &:hover {
                    color: $main-color;
                    transition: .2s;
                }
                @media (max-width:576px) {
                    width: 100%;
                }
            }
            &__delete {
                &::before {
                    content: '';
                    width: 20px;
                    height: 25px;
                    display: block;
                    position: relative;
                    mask-image: url('~@/assets/img/svg/trash.svg');
                    mask-size: contain;
                    mask-repeat: no-repeat;
                    background: #181818;
                    cursor: pointer;
                }
                &:hover {
                    &::before {
                        background: $main-color;
                    }
                    .tool {
                        display: flex;
                    }
                }
                .tool {
                    position: relative;
                    display: none;
                    transition: .2s;
                    width: auto;
                    &::before {
                        content: 'Remove from bookmarks';
                        display: block;
                        position: absolute;
                        top: -67px;
                        left: -134px;
                        width: max-content;
                        border-radius: 5px;
                        background: rgba(42, 53, 71, 0.9);
                        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
                        font-size: 13px;
                        padding: 5px 15px;
                        color: $white;
                    }
                    &::after {
                        content: "";
                        position: absolute;
                        border: 8px solid transparent;
                        border-top: 6px solid rgba(42, 53, 71, 0.9);
                        top: -44px;
                        left: 0px;
                    }
                }
            }
        }
        &-not-found {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 60px 0 100px 0;
            //@media (max-width:1200px) {
            //    margin-top: -40px;
            //}
            .not-found_img {
                background: url('~@/assets/img/svg/not-found__bookmarks.svg') no-repeat;
                background-size: contain;
            }
        }
        .confirm-modal__two-button-block {
            button {
                width: 48%
            }
        }
        .loadmore-btn {
            display: flex;
            justify-content: center;
        }
    }
</style>
