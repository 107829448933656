<template>
    <div
        class="confirm-modal"
        @click.self="close()"
    >
        <div class="confirm-modal__dialog">
            <header class="confirm-modal__header">
                <h2>Delete the bookmark</h2>
                <span
                    class="confirm-modal__close"
                    @click="close()"
                />
            </header>
            <section class="confirm-modal__content">
                Are you sure?
            </section>
            <footer>
                <div class="confirm-modal__two-button-block bid-buttons">
                    <button
                        class="btn-base btn-secondary"
                        @click.prevent="close()"
                    >
                        no
                    </button>
                    <button
                        class="btn-base btn-main"
                        @click.prevent="submit()"
                    >
                        yes
                    </button>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
// import Api from '@/helpers/api/index.js';
import { eventBus } from '@/helpers/event-bus'

import { createNamespacedHelpers } from 'vuex';

import { DELETE_DATA } from '../../store/modules/bookmarks/action-types';

const {
    mapActions: mapWriterActions
} = createNamespacedHelpers('bookmarks')

export default {
    name: 'DeleteBookmark',
    props: {
        id: [String, Number],
        referenceid: [String, Number]
    },
    data() {
        return {
            dialog: false
        }
    },
    methods: {
        ...mapWriterActions([
            DELETE_DATA
        ]),
        close() {
            this.$emit('close')
        },
        async submit() {
            await this.DELETE_DATA({ id: this.id })
            try {
                this.$emit('removeBookmark')
                eventBus.$emit('decreaseBookmarksCounter', this.referenceid);
                this.close()
                eventBus.$emit('showSnackBar', 'The bookmark has been deleted.', 'success');
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            }
        }
    }
}
</script>
